import { createTheme, darken, responsiveFontSizes } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    tertiary: Palette["primary"];
  }
  // allow configuration using `createTheme`
  interface PaletteOptions {
    tertiary?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
}

const theme = createTheme({
  palette: {
    mode: `dark`,
    primary: {
      main: `#D96534`,
    },
    secondary: {
      main: `#000000`,
    },
    tertiary: {
      main: `#ffffff`,
      contrastText: `#000000`,
    },
  },
  typography: {
    fontFamily: `'Exo', sans-serif`,
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiPaper: {
      defaultProps: {
        elevation: 5,
      },
    },
  },
});

const withScrollbarStyles = createTheme(theme, {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "&::selection, & *::selection": {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.dark,
        },
        "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
          width: theme.spacing(1),
        },
        "&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track": {
          background: theme.palette.primary.main,
          border: `${theme.spacing(0.5)}px solid ${theme.palette.secondary.light}`,
          borderRadius: theme.shape.borderRadius,
        },
        "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
          background: theme.palette.primary.dark,
          border: `${theme.spacing(0.5)}px solid ${theme.palette.secondary.light}`,
          borderRadius: theme.shape.borderRadius,
          transition: theme.transitions.create("all", {
            duration: theme.transitions.duration.complex,
            easing: theme.transitions.easing.easeOut,
          }),
        },
        "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
          backgroundColor: darken(theme.palette.primary.dark, 0.14),
        },
      },
    },
  },
});

export default responsiveFontSizes(withScrollbarStyles);
