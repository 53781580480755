exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-berichten-[id]-tsx": () => import("./../../../src/pages/berichten/[id].tsx" /* webpackChunkName: "component---src-pages-berichten-[id]-tsx" */),
  "component---src-pages-berichten-index-tsx": () => import("./../../../src/pages/berichten/index.tsx" /* webpackChunkName: "component---src-pages-berichten-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-reset-wachtwoord-tsx": () => import("./../../../src/pages/reset-wachtwoord.tsx" /* webpackChunkName: "component---src-pages-reset-wachtwoord-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */),
  "component---src-pages-verbruik-tsx": () => import("./../../../src/pages/verbruik.tsx" /* webpackChunkName: "component---src-pages-verbruik-tsx" */),
  "component---src-pages-wachtwoord-reset-tsx": () => import("./../../../src/pages/wachtwoord-reset.tsx" /* webpackChunkName: "component---src-pages-wachtwoord-reset-tsx" */)
}

