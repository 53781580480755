import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider as Provider } from "@mui/material/styles";
import React from "react";
import theme from "./theme";

const ThemeProvider: React.FC = ({ children }) => (
  <Provider theme={theme}>
    <CssBaseline />
    {children}
  </Provider>
);

export default ThemeProvider;
