import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import fetch from "isomorphic-fetch";

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      User: {
        fields: {
          tickets: {
            keyArgs: false,
            merge: (existing = [], incoming) =>
              [...existing, ...incoming].filter(
                (value, index, self) => index === self.findIndex((t) => t.__ref === value.__ref),
              ),
          },
        },
      },
      StatsByMonthType: {
        keyFields: ["month", "year"],
        merge: true,
      },
    },
  }),
  link: new HttpLink({
    uri: process.env.GATSBY_BACKEND_URL,
    credentials: "include",
    fetchOptions: {
      mode: "cors",
    },
    fetch,
  }),
  connectToDevTools: true,
});

export default client;
