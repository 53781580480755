import ApolloProvider from "@/apollo/provider";
import ThemeProvider from "@/theme/provider";
import { PageProps } from "gatsby";
import React from "react";

const WrapRoot: React.FC<PageProps> = ({ children }) => (
  <ApolloProvider>
    <ThemeProvider>{children}</ThemeProvider>
  </ApolloProvider>
);

export default WrapRoot;
